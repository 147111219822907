import React, { useContext, useState } from "react";
import download from "downloadjs";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  FaDownload,
  FaFileContract,
  FaPaste,
  FaPencilAlt,
  FaRegFilePdf,
  RiAddCircleFill,
} from "react-icons/all";

import WavesConfig from "config/waves";
import { ThemeContext } from "context/ThemeContext";
import sign from "assets/images/sign.png";
import styles from "./MutualCertificationCell.module.scss";

function MutualCertificationCell({ detail, owner, walletState, toggleDetail }) {
  const { theme } = useContext(ThemeContext);

  const timestamp = moment(detail.timestamp).toString();
  const split = detail.key.split("_");
  const txid = split[2];
  const creator = split[3];
  const isOwner = creator === owner ? true : false;
  const [flag, setFlag] = useState(false);
  const [copied, setCopied] = useState(false);

  const toggleDetails = () => {
    setFlag(!flag);
    toggleDetail(detail, !flag);
  };

  const DownloadCertificate = () => {
    fetch("/api/certifications/downloadCertificate", {
      method: "POST",
      body: JSON.stringify({
        txid,
        hash_title: "File hash",
        ...detail,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (resp) {
        return resp.blob();
      })
      .then(function (blob) {
        return download(blob, detail.title + ".pdf");
      });
  };

  const ShowIPFS = () => {
    window.open("https://ipfs.io/ipfs/" + detail.link);
  };

  return (
    <div
      className={styles.mutualCertification}
      key={detail.key}
      style={{
        backgroundColor: theme.stepBackground,
        boxShadow: theme.historyglow,
      }}
    >
      {isOwner ? (
        <FaFileContract
          className={styles.fileIcon}
          style={{ color: theme.iconBack }}
        />
      ) : (
        <img src={sign} className={styles.fileIcon} alt="" />
      )}
      <div className={styles.infomob} style={{ color: theme.primaryText }}>
        {timestamp}
      </div>
      <div className={styles.dataArea}>
        <div className={styles.timestampArea}>
          <div className={styles.info} style={{ color: theme.primaryText }}>
            {timestamp}
          </div>
          <div className={styles.actions}>
            {detail.link ? (
              <FaDownload
                className={styles.action}
                onClick={ShowIPFS}
                style={{ color: theme.iconBack }}
              />
            ) : null}
            {isOwner ? (
              <RiAddCircleFill
                className={styles.action}
                onClick={toggleDetails}
                style={{ color: theme.iconBack }}
              />
            ) : (
              <FaPencilAlt
                className={styles.action}
                onClick={toggleDetails}
                style={{ color: theme.iconBack }}
              />
            )}

            <div className={styles.copyitem}>
              <CopyToClipboard
                onCopy={() => {
                  setCopied(true);
                  setTimeout(() => {
                    document.querySelectorAll('.' + styles.copytooltip + '.' + styles.show).forEach((e) => {
                      e.classList.remove(styles.show);
                    })
                  }, 3000);
                }}
                text={WavesConfig.BASE_URL + "/explorer/" + txid}>
                <FaPaste
                  className={styles.action}
                  style={{ color: theme.iconBack }}
                />
              </CopyToClipboard>
              <span className={copied ? (styles.copytooltip + ' ' + styles.show) : styles.copytooltip}>Copied Successfully</span>
              <input hidden value={copied ? setTimeout(() => { setCopied(false) }, 1000) : null} />
            </div>
            <FaRegFilePdf
              className={styles.action}
              onClick={DownloadCertificate}
              style={{ color: theme.iconBack }}
            />
          </div>
        </div>
        <div className={styles.references} style={{ color: theme.primaryText }}>
          {isOwner ? (
            <>
              You created an agreement request: <b>{detail.title}</b>
            </>
          ) : (
            <>
              Your signature is requested: <b>{detail.title}</b>
            </>
          )}
          <br />
          Hash: <span>{detail.hash}</span>
          <br />
          TXId:{" "}
          <a
            href={`${WavesConfig.EXPLORER_URL}/tx/${txid}`}
            target="_blank"
            rel="noreferrer"
          >
            {txid}
          </a>
        </div>
        <div
          className={styles.referencesmob}
          style={{ color: theme.primaryText }}
        >
          {isOwner ? (
            <>
              You created an agreement request: <b>{detail.title}</b>
            </>
          ) : (
            <>
              Your signature is requested: <b>{detail.title}</b>
            </>
          )}
          <div className={styles.tmob}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Hash: <span>{detail.hash.slice(0, 25) + "..."}</span>
            </div>

            <div className={styles.copyitem}>
              <CopyToClipboard
                onCopy={() => {
                  setCopied(true);
                  setTimeout(() => {
                    document.querySelectorAll('.' + styles.copytooltip + '.' + styles.show).forEach((e) => {
                      e.classList.remove(styles.show);
                    })
                  }, 3000);
                }}
                text={detail.hash}>
                <FaPaste
                  className={styles.action}
                  style={{ color: theme.iconBack }}
                />
              </CopyToClipboard>
              <span className={copied ? (styles.copytooltip + ' ' + styles.show) : styles.copytooltip}>Copied Successfully</span>
              <input hidden value={copied ? setTimeout(() => { setCopied(false) }, 1000) : null} />
            </div>
          </div>
          <div className={styles.tmob}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              TXId:{" "}
              <a
                href={`${WavesConfig.EXPLORER_URL}/tx/${txid}`}
                target="_blank"
                rel="noreferrer"
              >
                {txid.slice(0, 25) + "..."}
              </a>
            </div>

            <div className={styles.copyitem}>
              <CopyToClipboard
                onCopy={() => {
                  setCopied(true);
                  setTimeout(() => {
                    document.querySelectorAll('.' + styles.copytooltip + '.' + styles.show).forEach((e) => {
                      e.classList.remove(styles.show);
                    })
                  }, 3000);
                }}
                text={txid}>
                <FaPaste
                  className={styles.action}
                  style={{ color: theme.iconBack }}
                />
              </CopyToClipboard>
              <span className={copied ? (styles.copytooltip + ' ' + styles.show) : styles.copytooltip}>Copied Successfully</span>
              <input hidden value={copied ? setTimeout(() => { setCopied(false) }, 1000) : null} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MutualCertificationCell;
