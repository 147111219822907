import React, { useContext, useState } from "react";
import { ThemeContext } from "context/ThemeContext";
import styles from "./Address.module.scss";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaCopy, FaPaste, FaWallet } from "react-icons/all";
function Address({
  title,
  icon,
  value,
  price,
  isBalance = true,
  isCopy = false,
  minDeposit = ''
}) {
  const { theme } = useContext(ThemeContext);
  const [copied, setCopied] = useState(false);
  const removeClass = (elem) =>{
    setTimeout(()=>{
      elem.classList.remove(styles.show);
    },3000);
    
  };
  return (
    <div className={styles.address} style={{ color: theme.primaryText }}>
      <div className={styles.header} style={{ color: theme.primaryText }}>
        {title}
      </div>
      <div className={styles.amount}>
        <div>
          <FaWallet size="16px" color={theme.iconBack} />
        </div>
        <div className={styles.value} style={{ color: theme.primaryText }}>
          {!isBalance
            ? value.slice(0, 5) + "..."
            : parseFloat(value).toFixed(4)}
          {isCopy ? (
            <div className={styles.copyitem}>
              <CopyToClipboard text={value}
              onCopy = {()=>{
                setCopied(true);
                setTimeout(()=>{
                  document.querySelectorAll('.'+styles.copytooltip+'.'+styles.show).forEach((e)=>{
                    e.classList.remove(styles.show);
                  })
                },3000);
              }}>
                <div>
                  <FaPaste
                    className={styles.action}
                    style={{ color: theme.iconBack }}
                  />
                </div>
              </CopyToClipboard>

              <span className={copied ? (styles.copytooltip + ' ' + styles.show) : styles.copytooltip }>Copied</span>
              <input hidden value={copied? setTimeout(()=>{setCopied(false)},1000) :null}/>
              
            </div>
          ) : (
            <></>
          )}
        </div>

      </div>
      <div style={{ color: theme.primaryText, fontSize: '16px', textAlign: 'center' }}>
        {
          !isBalance && minDeposit ? <>{minDeposit}</> : <></>
        }
      </div>
      {price !== undefined ? (
        <div className={styles.price} style={{ color: theme.primaryText }}>
          Current Price:{" "}
          <span className={styles.value}>USD {price.toFixed(4)}</span>
        </div>
      ) : null}

      <img src={icon} alt="" className={styles.imgMob} />
      <div className={styles.contentMob}>
        <div className={styles.valueMob}>
          <div style={{ color: theme.primaryText }}>{title}</div>
          &nbsp;&nbsp;&nbsp;
          {!isBalance ? (
            <div className={styles.copyitem}>
              <CopyToClipboard text={value} style={{ color: theme.buttonBack }}
                onCopy = {()=>{
                  setCopied(true);
                  setTimeout(()=>{
                    document.querySelectorAll('.'+styles.copytooltip+'.'+styles.show).forEach((e)=>{
                      e.classList.remove(styles.show);
                    })
                  },3000);
                }}>
                <FaCopy size="12px" />
              </CopyToClipboard>
              <span className={copied ? (styles.copytooltip + ' ' + styles.show) : styles.copytooltip }>Copied</span>
              <input hidden value={copied? setTimeout(()=>{setCopied(false)},1000) :null}/>
            </div>
              ) : (
            // <img src={copy} alt="" className={styles.imgMobIco}/>
            <div className={{}} style={{ color: theme.primaryText }}>
              {parseFloat(value).toFixed(4)}
            </div>
          )}
        </div>
        {price !== undefined ? (
          <div className={styles.priceMob} style={{ color: theme.primaryText }}>
            Current Price:{" "}
            <span className={styles.priceMobB}>USD {price.toFixed(4)}</span>
          </div>
        ) : !isBalance ? (
          <div className={styles.valueMob} style={{ color: theme.primaryText }}>
            {/* {value.slice(0, 26) + "..."} */}
            {value}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Address;