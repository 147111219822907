const themes = {
  regular: {
    itemBackground: '#ffffff',
    modalBackground: '#ffffff',
    menuBackground: '#000451',
    grayText: '#707070',
    textColor: '#000000',
    imgWrapper: '#ffffff',

    menuItemBorder: '#d8d8d8',
    
    primaryColor: '#d359a6',
    commentText: 'rgba(21, 20, 57, 0.4)',
    verificationColor:'#FE006C',
    footerBackground: '#000451',
    stepBackground: 'white',
    disabledButtonBack: '#BFBFBF',
    signUp: '#FE006C',
    menuText: '#FE006C',
    plusCircle: '#1E0E62',
    dropZone: '#000000',
    checkbox: '#FE006C',
    selectboxText: '#1E0E62',
    sliderBackground: '#f1eef0',
    glow: '0px 20px 30px rgba(254, 0, 108, 0.2)',
    feeText: '#686868',
    bidModalBackground: '#F7F9FA',
    primaryText: '#1E0E62',
    certificationText: '#1E0E62',
    highlightText: '#d359a6',
    primaryBack: '#ffffff',
    secondaryBack: "#f7f7f7",
    buttonBack: '#FE006C',
    runningButtonBack: '#219653',
    iconBack: '#FE006C',
    hamburger: '#000000',
    balancesBack: '#ffffff',
    overviewPriceBack: 'rgba(191, 191, 191, 0.2)',
    overviewPriceBorder: '#FE006C',
    overviewPrimaryText: 'white',
    overviewSecondaryText: '#1E0E62',
    overviewTransactionArrow: '#d359a6',
    overviewTransactionTimestamp: '#d359a6',
    overviewTransactionId: '#a2a2a2',
    overviewTransactionEnvelope: '#868686',
    manageTokenHighlight: '#d359a6',
  },
  dark: {
    itemBackground: 'rgb(85, 79, 109)',
    menuBackground: '#000451',
    modalBackground: 'rgb(59, 32, 66)',
    grayText: '#aa91d8',
    textColor: '#ffffff',
    imgWrapper: '#7a7298',

    menuItemBorder: '#151519',
    
    primaryColor: '#3b2042',
    commentText: '#d6d0e0',
    verificationColor:'#FE006C',
    footerBackground: 'rgb(29, 30, 39)',
    stepBackground: 'rgb(53, 54, 78)',
    disabledButtonBack: '#BFBFBF',
    signUp: '#d6d0e0',
    menuText: 'white',
    plusCircle: 'white',
    dropZone: '#ffffff',
    checkbox: '#FE006C',
    selectboxText: 'black',
    sliderBackground: '#988ec3',
    glow: '0px 20px 30px #262626',
    feeText: '#caa2a2',
    bidModalBackground: '#443939',
    nonglow: '0px 20px 30px #262626',
    primaryText: 'white',
    certificationText: '#1E0E62',
    highlightText: '#ffffff',
    primaryBack: '#383250',
    secondaryBack: "#554f6d",
    buttonBack: '#FE006C',
    runningButtonBack: '#219653',
    iconBack: '#46091a',
    hamburger: '#ffffff',
    balancesBack: 'rgb(29, 30, 39)',
    overviewPriceBack: 'rgba(191, 191, 191, 0.2)',
    overviewPriceBorder: '#8a2e87',
    overviewPrimaryText: '#ffffff',
    overviewSecondaryText: '#ffffff',
    overviewTransactionArrow: '#d6d0e0',
    overviewTransactionTimestamp: '#8f7ab7',
    overviewTransactionId: '#8f7ab7',
    overviewTransactionEnvelope: '#d6d0e0',
    manageTokenHighlight: '#d6d0e0',
  },
  waves: {
    itemBackground: '#ffffff',
    modalBackground: '#ffffff',
    menuBackground: '#000451',
    grayText: '#707070',
    textColor: '#ffffff',
    imgWrapper: '#ffffff',
    
    menuItemBorder: '#d8d8d8',
    
    primaryColor: '#0055ff',
    commentText: 'rgba(21, 20, 57, 0.4)',
    verificationColor: '#0055ff',
    footerBackground: '#000451',
    stepBackground: 'white',
    disabledButtonBack: '#BFBFBF',
    signUp: '#0055ff',
    menuText: '#1E0E62',
    plusCircle: '#0055ff',
    dropZone: '#000000',
    checkbox: '#0055ff',
    selectboxText: '#1E0E62',
    sliderBackground: '#f1eef0',
    glow: '0px 20px 30px #859fca',
    feeText: '#686868',
    bidModalBackground: '#F7F9FA',
    primaryText: '#1E0E62',
    certificationText: '#1E0E62',
    highlightText: '#0055ff',
    primaryBack: '#ffffff',
    secondaryBack: "#f7f7f7",
    buttonBack: '#0055ff',
    runningButtonBack: '#0055ff',
    iconBack: '#0055ff',
    hamburger: '#000000',
    balancesBack: '#ffffff',
    overviewPriceBack: 'rgba(191, 191, 191, 0.2)',
    overviewPriceBorder: '#0055ff',
    overviewPrimaryText: '#ffffff',
    overviewSecondaryText: '#ffffff',
    overviewTransactionArrow: '#0055ff',
    overviewTransactionTimestamp: '#0055ff',
    overviewTransactionId: '#a2a2a2',
    overviewTransactionEnvelope: '#868686',
    manageTokenHighlight: '#0055ff',
  }
};

export default themes;