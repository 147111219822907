import React, {useContext} from 'react'
import styles from './About.module.scss'
import {ThemeContext} from 'context/ThemeContext'

function About(){
    const {theme} = useContext(ThemeContext)

    return(
        <div className={styles.verficationExplorer}>
            <div className={styles.container} style={{color: theme.primaryText, textAlign:'justify'}}>
                <span className={styles.header}>About Hashgreed</span>
                <div className={styles.aboutData}>
                    <br/>
                    Hashgreed is Africa’s first nft marketplace for creative, commerce and asset tokenization.
                    <br/><br/>
                    Hashgreed is a web and mobile decentralized application by Vinekross Technologies Limited who run the Krosscoin Project.
                    <br/><br/>
                    Vinekross Technologies Limited (RC1883948) is a Nigerian registered company.
                </div>
            </div>
        </div>

    )
}

export default About